<template>
  <OnofficeActivation marketplace-integration="SYNCHRONISATION">
    <template slot="description">
      Fügen Sie den API-Key ein, klicken Sie auf "Freischalten" und <strong>bestätigen Sie den Link in der E-Mail</strong>.
    </template>
    <template slot="success">
      <b>Geschafft!</b><br>
      Sie bekommen nun eine E-Mail mit einem Bestätigungslink.<br>
      Klicken Sie auf diesen Link, um die Verknüpfung mit onOffice abzuschließen.
    </template>
  </OnofficeActivation>
</template>

<script>
import OnofficeActivation from './OnofficeActivation.vue'

export default {
  components: { OnofficeActivation }
}
</script>
