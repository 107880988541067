<template>
  <OnofficeActivation marketplace-integration="APP">
    <template slot="description">
      Fügen Sie den API-Key ein und klicken Sie auf "Freischalten".
    </template>
    <template slot="success">
      <b>Geschafft!</b><br>
      Sie können jetzt BOTTIMMO Light direkt im Marketplace nutzen.
      Unter "Marketplace" -> "BOTTIMMO Light" -> "Lightprodukt" finden Sie die App.
    </template>
  </OnofficeActivation>
</template>

<script>
import OnofficeActivation from './OnofficeActivation.vue'

export default {
  components: { OnofficeActivation }
}
</script>
