<template>
  <div>
    <div
      v-if="showRemaxInfo"
      style="max-width: 800px;"
      class="pa-0 ma-0"
    >
      <div class="text-body-1">
        RE/MAX Deutschland bietet allen Franchise-Nehmern den <strong>LeadTurbo powered by BOTTIMMO</strong> an.<br>
        Der LeadTurbo bietet RE/MAX-spezifisches Branding und Werbemittel. Er funktioniert ansonsten
        genau wie die BOTTIMMO-App.
      </div>
      <div class="text-body-1">
        Wie sie den LeadTurbo nutzen können erfahren Sie hier: <a href="https://www.remax.de/leadturbo-info">www.remax.de/leadturbo-info</a>
      </div>
    </div>
    <div
      v-else
      style="height: 150px;"
      class="w-full d-flex flex-column white"
    >
      <div class="pa-0 ma-0">
        <div class="w-full">
          <slot name="description" />
        </div>
      </div>

      <div
        v-if="!isFinished"
        style="max-width: 800px;"
        class="d-flex flex-row align-start pa-0 ma-0"
      >
        <v-form
          ref="apiKeyForm"
          class="w-full mr-2"
          @submit.prevent="submit"
        >
          <CheckTextField
            ref="apiKey"
            v-model="apiKey"
            class="my-1 w-full text--small"
            placeholder="kUXjFT0talJdT4PZ3znW1prJmUe5REnd5rTQPTRCQMX4dBMSIOmq7wMZE5m6u9j2"
            outlined
            dense
            :error-messages="errorMessage && [errorMessage]"
            :rules="[rules.required, rules.tokenLength]"
            persistent-placeholder
            validate-on-input
          />
        </v-form>

        <v-btn
          color="primary"
          class="mt-1"
          :loading="isSubmitting"
          :disabled="isDisabled"
          @click="submit"
        >
          Freischalten
        </v-btn>
      </div>

      <div>
        <v-alert
          v-if="isFinished"
          dense
          style="max-width: 700px;"
          type="success"
          class="mt-0"
        >
          <p class="ma-0">
            <slot name="success" />
          </p>
        </v-alert>
      </div>
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue'
import brandingMixin from '@/mixins/branding'
import CONNECT_TO_ONOFFICE_MARKETPLACE from './ConnectToOnofficeMarketplace.gql'
import CheckTextField from '@/components/forms/CheckTextField'
import { required } from '@/lib/validation'

export default {
  components: { CheckTextField },
  mixins: [brandingMixin],
  props: {
    marketplaceIntegration: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      apiKey: '',
      submitState: 'IDLE',
      error: null
    }
  },
  computed: {
    rules () {
      return {
        required,
        tokenLength: (value) => value.length === 0 || value.length === 64 || 'Der API-Key muss 64 Zeichen lang sein.'
      }
    },
    showRemaxInfo () {
      return this.error === 'remaxNotAllowed'
    },
    isSubmitting () {
      return this.submitState === 'SUBMITTING'
    },
    isFinished () {
      return this.submitState === 'FINISHED' && !this.error
    },
    isDisabled () {
      return this.isSubmitting || this.isFinished || !this.apiKey || this.apiKey.length !== 64
    },
    errorMessage () {
      return {
        invalidHMAC: 'Der API-Key ist leider nicht korrekt. Bitte kopieren Sie den API-Key erneut in das Feld.',
        missingParameters: 'Die Freischaltung ist leider fehlgeschlagen. Bitte laden Sie die Seite neu oder wenden sich an die obige E-Mail Adresse.',
        invalidTimestamp: 'Der API-Key ist abgelaufen. Bitte laden Sie die Seite neu und versuchen Sie es erneut.',
        invalidApiKey: 'Der API-Key ist ungültig. Bitte kopieren Sie den API-Key oben und versuchen Sie es erneut.',
        accountAlreadyExists: {
          APP: 'Für diesen Mandant exisitiert bereits ein BOTTIMMO Konto. Wenn Sie bereits BOTTIMMO Kunde sind, können Sie die Synchronisation in den BOTTIMMO Einstellungen aktivieren. Sonst wenden Sie sich bitte an die obige E-Mail Adresse.',
          SYNCHRONISATION: 'Für diesen Mandant exisitiert bereits ein BOTTIMMO Konto. Wenn Sie bereits BOTTIMMO Light nutzen, wird diese App nicht benötigt. Sie können Sie die Synchronisation in den BOTTIMMO Einstellungen aktivieren. Sonst wenden Sie sich bitte an die obige E-Mail Adresse.'
        }[this.marketplaceIntegration],
        remaxNotAllowed: 'RE/MAX Deutschland bietet allen Franchise-Nehmern den LeadTurbo powered by BOTTIMMO an (https://www.remax.de/leadturbo-info). ',
        unknown: 'Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es erneut oder wenden sich an die obige E-Mail Adresse.'
      }[this.error] || ''
    }
  },
  methods: {
    async submit () {
      this.error = null
      this.submitState = 'SUBMITTING'
      try {
        try {
          Sentry.captureMessage('CONNECT_TO_ONOFFICE_MARKETPLACE: ' + window.location.href.match(/customerName=(.*)&customer/)[1], {
            extra: {
              marketplaceIntegration: this.marketplaceIntegration,
              ...window.location.href?.split('&')?.slice(1)?.reduce((obj, e) => ({ ...obj, [e.split('=')[0]]: e.split('=')[1] }), {})
            }
          })
          Sentry.addBreadcrumb({
            data: {
              marketplaceIntegration: this.marketplaceIntegration,
              url: window.location.href
            }
          })
          Sentry.addBreadcrumb({
            message: 'CONNECT_TO_ONOFFICE_MARKETPLACE',
            data: {
              marketplaceIntegration: this.marketplaceIntegration,
              ...window.location.href?.split('&')?.slice(1)?.reduce((obj, e) => ({ ...obj, [e?.split('=')[0]]: e?.split('=')[1] }), {})
            }
          })
        } catch (e) {
          Sentry.captureMessage('Failed to Log to Sentry')
        }
        await this.$apollo.mutate({
          mutation: CONNECT_TO_ONOFFICE_MARKETPLACE,
          variables: {
            input: {
              marketplaceIntegration: this.marketplaceIntegration,
              secret: this.apiKey,
              url: window.location.href
            }
          }
        })
        setTimeout(() => {
          // set status in onoffice marketplace parent window
          window.parent.postMessage('active', '*')
        }, 1000)
      } catch (err) {
        if (err?.message?.match(/The HMAC is invalid/)) {
          this.error = 'invalidApiKey'
        } else if (err?.message?.match(/Invalid timestamp/)) {
          this.error = 'invalidTimestamp'
        } else if (err?.message?.match(/Account already exists/)) {
          this.error = 'accountAlreadyExists'
        } else if (err?.message?.match(/No REMAX-DE customers allowed/)) {
          this.error = 'remaxNotAllowed'
        } else {
          Sentry.captureException(err, {
            extra: {
              marketplaceIntegration: this.marketplaceIntegration,
              url: window.location.href,
              error: err.message,
              type: 'UnknownOnofficeActivationError'
            }
          })
          this.error = 'unknown'
          try {
            Sentry.captureException(err, {
              extra: { err }
            })
          } catch (e) {}
        }
      } finally {
        this.submitState = 'FINISHED'
      }
    }
  }
}
</script>
